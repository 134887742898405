<template>
  <section
    :style="getStyleMain"
    :class="[
      $customSectionClasses(props.cfgs),
      isParallax ? '' : 'not-parallax'
    ]"
    class="main-slider"
  >
    <div class="main-slider__root">
      <div class="main-slider__wrapper">
        <div
          :style="getStyleMain"
          class="adaptive-img main-slider__swiper"
        >
          <swiper
            :loop="true"
            :speed="1500"
            :autoplay="timeoutSlideAuto"
            :modules="[Pagination,Autoplay]"
            :pagination="{
              clickable:true,
              el:'.main-home-pagination'
            }"
            class="main-home-swiper"
            @swiper="mainHomeSwiper.setSliderRef"
          >
            <swiper-slide
              v-for="(item, idx) in imgList"
              :key="`slide-${item.title}-${idx}`"
            >
              <HomeSlide
                :idx="idx"
                :video-link="extras.videos[idx]"
                :slide="item"
                :min-height-desktop="values?.min_height_desktop || 768"
                :min-height-mobile="values.min_height_mobile || 644"
              />
            </swiper-slide>
            <div class="container">
              <div class=" main-home-pagination"></div>
            </div>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperClass } from 'swiper/types';
import { Pagination, Autoplay, Virtual } from 'swiper/modules';
import { setCustomSwiper } from '~/utils/swiper/setCustomSwiper';

import type { SectionTypes } from '~/plugins/customSectionClasses';
import { ref } from '#imports';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { ItemsValuesMainSliderBlock } from '~/types/SliderBlocksType';

const { $customSectionClasses } = useNuxtApp();

const mainHomeSwiperRef = ref<SwiperClass | null>(null);
const mainHomeSwiper = setCustomSwiper(mainHomeSwiperRef);

type ValuesMainSliderBlock = {
	min_height_desktop?: number;
	min_height_mobile: number;
	parallax: boolean;
	items: ItemsValuesMainSliderBlock[];
}

type MainSliderBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs' | 'extras'> & {
	values: ValuesMainSliderBlock;
	cfgs: SectionTypes & {
		timeout_slide_auto: number;
	};

	extras: {
		videos: string[];
		items: ItemsValuesMainSliderBlock[];
	}
}
const props = defineProps<MainSliderBlockType>();

const imgList = computed(() => props.extras.items);

const timeoutSlideAuto = computed(() => ((props.cfgs?.timeout_slide_auto || 0) === 0
  ? false
  : {
      delay: props.cfgs.timeout_slide_auto * 1000,
      disableOnInteraction: false,
      waitForTransition: false,
      pauseOnMouseEnter: true
    }));

const cssAnimationTimeout = ref(`${props.cfgs.timeout_slide_auto}s`);

const getStyleMain = computed(() => {
  const list = [
		`--css-animation-timeout: ${cssAnimationTimeout.value || 0}`
  ];
  const extraHeight = isParallax.value ? 60 : 0;

  if (props.values.min_height_desktop) {
    list.push(`--pc-min-height: ${props.values.min_height_desktop + extraHeight}px`);
  }
  if (props.values.min_height_mobile) {
    list.push(`--mobile-min-height: ${props.values.min_height_mobile + extraHeight}px`);
  }

  return list.join('; ');
});

const isParallax = computed(() => Boolean(props.values.parallax));
</script>

<style lang="scss">
@import "@/assets/scss/media";

.main-home-swiper {
	width: 100%;
	height: 100%;

	& > .container {
		position: relative;
	}

	& .swiper-wrapper {
		height: 100%;
	}

	& .swiper-slide {
		height: 100%;
	}
}

.main-slider {
	position: relative;
	height: var(--pc-min-height, 768px);
	margin-bottom: -60px;

	&.custom-section {
		padding-top: 0;
	}

	&.not-parallax {
		margin-bottom: 0;

		& .main-slider__root {
			position: relative;
		}

		& .home-slide__container {
			margin-top: 0;
			padding-bottom: 0;
		}
	}
}

.main-home-pagination {
	position: absolute;
	z-index: 1;
	left: 0;
	bottom: 12px !important;
	display: flex;
	overflow: auto;
	max-width: 436px;
	width: 100%;

	& > .swiper-pagination-bullet {
		border-radius: 30.667px;
		opacity: 1;
		background: rgb(255 255 255 / 30%);
		width: 76px;
		height: 4px;
		flex: 1;

		&::after {
			position: absolute;
			inset: 0;
			z-index: 50;
			height: 5px;
			width: 0;
			opacity: 0;
		}
	}

	& > .swiper-pagination-bullet-active {
		width: 240px;
		position: relative;
		overflow: hidden;
		flex: 5;

		&::after {
			content: "";
			opacity: 1;
			background-color: white;
			animation-delay: 10ms;
			animation-duration: var(--css-animation-timeout);
			animation-timing-function: linear;
			animation-name: slider-block-swiper-pagination-active;
		}
	}
}

@include media('md') {
	.main-slider {
		height: var(--mobile-min-height, 584px);
	}

	.main-home-pagination {
		bottom: 32px !important;

		& > .swiper-pagination-bullet {
			width: 56px;
		}

		& > .swiper-pagination-bullet-active {
			width: 140px;
		}
	}

	.main-home-swiper {
		height: 100%;

		& .swiper-wrapper {
			height: 100%;
		}

		& .swiper-slide {
			height: 100%;
		}
	}
}

@keyframes slider-block-swiper-pagination-active {
	from {
		width: 0;
	}

	to {
		width: 100%;
	}
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/media";

.main-slider__root {
	//max-width: 1900px;
	margin: 0 auto;
	position: fixed;
	width: 100%;
	height: auto;
	top: 0;
	left: 0;
	clip-path: inset(0 0 0 0);
	background: var(--primary);
}

.main-slider__wrapper {
	display: flex;
	gap: 20px;
}

.main-slider__swiper {
	width: 100%;
	max-height: var(--pc-min-height, 768px);
	min-height: var(--pc-min-height, 768px);

	&::before {
		padding-top: 50%;
	}
}

.main-home-swiper {
	position: absolute;
}

@include media('lg') {
}

@include media('md') {
	.main-slider__root {
		z-index: 1;

		&::before {
			content: '';
			position: absolute;
			height: 20px;
			width: 100%;
			bottom: 0;
			left: 0;
			border-radius: 20px 20px 0 0;
			background: white;
			z-index: 2;
		}
	}

	.main-slider__swiper {
		width: 100%;
		max-height: var(--mobile-min-height, 584px);
		min-height: var(--mobile-min-height, 584px);

		&::before {
			padding-top: 50%;
		}
	}
}

@include media('sm') {
	.main-home-pagination {
		left: 20px;
		width: calc(100vw - 32px);
	}
}
</style>
